body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html,
body {
  width: 100%;
  height: 100%;
  margin: 0px;
  padding: 0px;
  overflow-x: hidden;
  background: #7c504c;
}

p {
  margin-bottom: 0;
  padding: 0;
}

/* Change color of schedule table to white text & same background when hovering */
/* .table-hover tbody tr:hover td,
.table-hover tbody tr:hover th {
  color: white;
  background-color: #1e3533;
  border-radius: 10px;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@font-face {
  font-family: "Aldo";
  src: local("Aldo"), url(./assets/fonts/AldotheApache.ttf) format("truetype");
}
